import React, { useRef } from "react"
import { graphql } from "gatsby"
import { Waypoint } from "react-waypoint"
import Footer from "../components/footer"
import Menubar from "../components/menubar"
import Seo from "../components/seo"
import Layout from "../components/layout"
import ReactPlayer from "react-player"

export const Head = () => <Seo title="Welcome" />

const Page = props => {
  const backDrop = useRef(null)
  const handlePositionChange = () => {
    //
  }
  const launchTool = mode => {
    let url = ""
    if (mode === "asm") {
      url = "https://v2.rentalcal.eu/signup/"
    } else {
      url = "https://webtool.rentalcal.eu/"
    }

    window.open(url, "_blank")
  }

  return (
    <Layout>
      <div className="container mx-auto" id="root" ref={backDrop}>
        <Menubar />
        <div id="body">
          <section>
            <h3 id="about">About the project</h3>
            <div className="mt-6">
              The project’s key goal is to improve market transparency for
              energy efficiency investments in the rental housing industry. It
              does so by providing a benchmarking tool for transnational
              profitability assessments of such investments. With the
              development and dissemination of this tool, the project follows
              three distinct scopes.
            </div>
            <div className="mt-6">
              <strong>First</strong>, it uses profitability benchmarking results
              to provide specific information to real estate owners in national
              housing markets about profitable investments and cost optimal
              energy efficiencies that are achievable through refurbishment
              investments. The focus is to improve target group specific
              knowledge and facilitate investment decisions.
            </div>
            <div className="mt-6">
              <strong>Second</strong>, it provides a “tool to talk” to
              strengthen dialog between the housing industry, consultants and
              financial market actors concerning planning, running, financing
              and regulating energy efficiency refurbishments in the private
              rental sector.
            </div>
            <div className="mt-6">
              <strong>Third</strong>, it uses a standardized methodology in
              order to allow for a transparent comparison of investment
              conditions in different EU countries. This should lead to
              increased competition between countries, pave the way towards a
              harmonization process of the markets, and remove investment
              barriers in national housing markets. In the long run, this will
              lead to a stronger integration of European capital markets for
              investments in sustainable energy in the building sector.
            </div>
            <div className="mt-8">
              RentalCal was an EU funded project running from 2015-2018. The
              project consisted of 11 research organizations from 8 european
              countries.
            </div>
          </section>
          <section>
            <h3 id="tool">The RentalCal Tool</h3>
            <p>
              The RentalCal Profitability Calculation tool is a wide-ranging,
              straightforward tool providing an open and objective comparison of
              the retrofit opportunities available to investors in the
              residential rental market. The tool adopt established
              methodologies and valid input parameters to evaluate various
              energy retrofits and costs of such investment opportunities.
            </p>

            <h4 className="mb-3">
              The RentalCal Tool consists of three parts:
            </h4>
            <Waypoint onPositionChange={handlePositionChange} />
            <ul className="list-outside list-disc space-y-2 ml-4">
              <li>
                In the first part, the basic information to understand and apply
                the tool is provided to the user. This includes a comprehensive
                presentation and a video tutorial.
              </li>
              <li>
                In the second step, the necessary input data for the
                profitability calculation is requested from the user.
              </li>
              <li>
                For the third step (output data), the tool calculates the
                profitability of the investment in question and displays the
                results to the user. This also include a feature enabling
                sensitivity and probability analyses of the relationships
                between the input dassta and the output data.
              </li>
            </ul>
            <div className="flex flex-row justify-center">
              <img
                src="/images/flow.en@2x.png"
                alt="RentalCal Tool"
                width={509}
                height={540}
              />
            </div>
          </section>
          <section id="launch">
            <h3>Launch the Tool</h3>
            <div className="flex flex-row space-x-20">
              <div className="w-1/2">
                <h5 className="font-bold pt-1 pb-3">Assisted Mode*</h5>
                <p className="h-[280px]">
                  Profitability Calculation for a selected reference building
                  and a selection of default bundles of measures from TABULA and
                  the RentalCal databases. Default values for energy
                  consumption, average cost figures for measures and energy
                  savings are provided by the tool, which might be overwritten
                  by the user if more accurate information is available.
                  Moreover, the RentalCal consortium has worked out estimations
                  for measure costs which are provided as suggestion values.
                  This option is especially suitable for users who do not have a
                  specific building in mind, but would like to gain a general
                  insight into the profitability of energy efficiency retrofit
                  in rental housing within different building types, ages and
                  measures across the eight European countries. This option is
                  intended for landlords who want to perform a profitability
                  calculation for a specific building which does not come close
                  enough to the offered reference buildings and who do not have
                  obtained a complete energy concept (current consumption,
                  bundle of measures and expected savings) yet.
                </p>
                <div className="flex justify-center items-center h-[30px]"><img src="/images/zb_logo@3x.png" alt="Zukunft Bau Logo" width="180"/></div>
                <p className="pt-4 font-medium">
                  *Note: Version 2 is currently WIP (Note:
                  currently only the german case is available).
                </p>
                <div className="flex justify-center mt-10">
                  <button
                    type="button"
                    onClick={() => launchTool("asm")}
                    className="text-sm bg-blue-600 text-white p-3 font-medium shadow-md hover:shadow-lg hover:shadow-blue-300"
                  >
                    Start Assisted Mode &raquo;
                  </button>
                </div>
              </div>
              <div className="w-1/2">
                <h5 className="font-bold pt-1 pb-3">Freehand Mode*</h5>
                <p className="h-[280px]">
                  Profitability Calculation for an own energy concept without
                  any default values for energy consumption and savings,
                  investment cost or suggestions on the right bundle of
                  measures. If the required input data are available, this
                  option is faster and more precise. It is especially intended
                  for energy consultants, civil engineers, architects, owner’s
                  associations and other building professionals who know the
                  technical details and potential costs of the retrofit and want
                  to perform a quick profitability calculation. Moreover,
                  landlords who are consulted by these groups may also use this
                  option.
                </p>
                <div className="flex justify-center items-center h-[30px]"></div>
                <p className="pt-4 font-medium">
                  *Note: Version 1 of the Webtool.
                </p>
                <div className="flex justify-center mt-10">
                  <button
                    type="button"
                    onClick={() => launchTool("free")}
                    className="text-sm bg-blue-600 text-white p-3 font-medium shadow-md hover:shadow-lg hover:shadow-blue-300"
                  >
                    Start Freehand Mode &raquo;
                  </button>
                </div>
              </div>
            </div>
          </section>

          <section id="manual">
            <h3>User Manual</h3>
            <p>
              The User manual is available in English and German. Click the
              image to view the corresponding PDF.
            </p>
            <div className="flex flex-row space-x-4 w-full items-center p-10">
              <div className="flex-grow w-1/2  flex justify-center">
                <div className="flex flex-col flex-grow-0 content-center">
                  <a
                    href="/pdf/RentalCal.UserManual.v2.0.EN.pdf"
                    target="_blank"
                    className=""
                  >
                    <img
                      src="/images/rc.manual.en.png"
                      alt="English RentalCal Webtool Manual"
                      width="171"
                      height="228"
                    />
                  </a>
                  <a
                    href="/pdf/RentalCal.UserManual.v2.0.EN.pdf"
                    target="_blank"
                    className="text-center font-medium"
                  >
                    <span className="text-sm text-center pt-3">
                      English Manual Version 2 (PDF)
                    </span>
                  </a>
                </div>
              </div>
              <div className="flex-grow w-1/2  flex justify-center">
                <div className="flex flex-col flex-grow-0">
                  <a
                    href="/pdf/RentalCal.UserManual.v2.0.DE.pdf"
                    target="_blank"
                  >
                    <img
                      src="/images/rc.manual.de.png"
                      alt="German RentalCal Webtool Manual"
                      width="171"
                      height="228"
                    />
                  </a>
                  <a
                    href="/pdf/RentalCal.UserManual.v2.0.DE.pdf"
                    target="_blank"
                    className="text-center font-medium"
                  >
                    <span className="text-sm text-center pt-3">
                      German Manual Version 2 (PDF)
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section id="stories">
            <h3>Case Stories</h3>
            <p className="mt-5 mb-5">
              To give an impression of the results, one can achieve from using
              the RentalCal tool, we have gathered a number of case stories
              (Freehand Mode).
            </p>
            <div className="flex flex-row flex-wrap">
              {props.data.casestories.edges.map((item, index) => {
                return (
                  <div
                    key={`${item.node.title}${index}`}
                    className="border p-3 bg-white/60 mr-10 mb-10 flex flex-col shadow-sm hover:shadow-md hover:shadow-slate-200 space-y-4"
                  >
                    <a href={`/pdf/${item.node.pdf}`}>
                      <img
                        src={`/images/covers/${item.node.thumb}`}
                        alt={`Case Story ${item.node.title}`}
                        width={160}
                        height={285}
                      />
                    </a>
                    <div className="text-center font-medium">
                      <a href={`/pdf/${item.node.pdf}`}>{item.node.title}</a>
                    </div>
                  </div>
                )
              })}
            </div>
          </section>
          <section>
            <h3 id="tutorial">Video Tutorial</h3>
            <div className="flex flex-row justify-center w-full ">
              <ReactPlayer url="https://youtu.be/0ub9-GM0sVY" controls={true} />
            </div>
          </section>
          <section className="pt-10" id="toolparams">
            <h3>Tool Parameters</h3>
            <p>
              It is important to find the necessary information, before you
              start the tool - this makes the process much easier. You can find
              the necessary information in the illustrations in the manual.
            </p>
            <p>
              The webtool is available in seven different languages. You choose
              the language in the upper, left corner of the tool.
            </p>
            <p className="mb-5 leading">
              As a result of using the RentalCal tool, you get a number of
              KPI&apos;s - Key Performance Indicators on rentability of
              investments. But the tool not only deals with the calculation of
              economic efficiency from investors and tenants perspective, it
              also analyses the consumption of energy and resources.
            </p>
            <div className="w-full items-center flex content-center flex-wrap">
              {props.data.params.edges.map((item, index) => {
                return (
                  <div
                    key={`${item.node.title}${index}`}
                    className="border p-3 bg-white/60 mr-10 mb-10 flex flex-col shadow-sm hover:shadow-md hover:shadow-slate-200 space-y-4"
                  >
                    <a href={`/pdf/${item.node.pdf}`}>
                      <img
                        src={`/images/factsheets/${item.node.thumb}.png`}
                        alt={`Tool parameters ${item.node.title}`}
                        width={160}
                        height={285}
                      />
                    </a>
                    <div className="text-center font-medium">
                      <a href={`/pdf/${item.node.pdf}`}>{item.node.title}</a>
                    </div>
                  </div>
                )
              })}
            </div>
          </section>

          <br />
          <Footer />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    de_factsheets: allFile(
      filter: {
        sourceInstanceName: { eq: "casestory" }
        name: { glob: "Country.Fact.Sheet.Tool.Parameters.Germany*" }
      }
    ) {
      edges {
        node {
          name
          relativePath
        }
      }
    }
    casestories: allStoryDataJson {
      edges {
        node {
          thumb
          pdf
          title
        }
      }
    }
    params: allToolparamDataJson {
      edges {
        node {
          title
          pdf
          thumb
        }
      }
    }
  }
`

export default Page
