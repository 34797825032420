import React from "react"
import Sticky from "react-stickynode"
import { Link } from "react-scroll"

export default function Menubar(props) {
  const handleSetActive = e => {}

  return (
    <Sticky top={0} innerZ={1005}>
      <div className="flex flex-row items-center h-[70px] w-full">
        <div
          id="menubar"
          className="sticky rounded-none shadow-md bg-white/60 h-[70px] w-full backdrop-blur-lg flex flex-row items-center space-x-10 pl-3"
        >
          <div id="left" className="flex-grow-0">
            <img
              src="/images/rclogoh.png"
              alt="RentalCal Logo"
              width={134}
              height={48}
            />
          </div>
          <div id="mid" className="flex-grow"></div>
          <div id="right" className="flex flex-row space-x-20 pr-20">
            <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={-140}
              duration={300}
              onSetActive={handleSetActive}
            >
              About
              <br />
              the project
            </Link>
            <Link
              activeClass="active"
              to="tool"
              spy={true}
              smooth={true}
              offset={-140}
              duration={300}
              onSetActive={handleSetActive}
            >
              RentalCal
              <br />
              Tool
            </Link>
            <Link
              activeClass="active"
              to="launch"
              spy={true}
              smooth={true}
              offset={-80}
              duration={300}
              onSetActive={handleSetActive}
            >
              Launch
              <br />
              Tool
            </Link>
            <Link
              activeClass="active"
              to="manual"
              spy={true}
              smooth={true}
              offset={-100}
              duration={300}
              onSetActive={handleSetActive}
            >
              User
              <br />
              Manual
            </Link>
            <Link
              activeClass="active"
              to="stories"
              spy={true}
              smooth={true}
              offset={-100}
              duration={300}
              onSetActive={handleSetActive}
            >
              Case
              <br />
              Stories
            </Link>
            <Link
              activeClass="active"
              to="tutorial"
              spy={true}
              smooth={true}
              offset={-120}
              duration={300}
              onSetActive={handleSetActive}
            >
              Video
              <br />
              Tutorial
            </Link>
            <Link
              activeClass="active"
              to="toolparams"
              spy={true}
              smooth={true}
              offset={-80}
              duration={300}
              onSetActive={handleSetActive}
            >
              Tool
              <br />
              Parameters
            </Link>
          </div>
        </div>
      </div>
    </Sticky>
  )
}
