import React from "react"

export default function Footer() {
  return (
    <section id="footer" className="pt-20 mt-10 mb-32">
      <div className="flex flex-row space-x-16" id="contact">
        <div id="fleft" className="flex-grow-0">
          <h5 className="font-medium">Contact Info</h5>
          <ul className="text-sm pt-3">
            <li>RentalCal Consortium</li>
            <li>c/o IWU</li>
            <li>Institut Wohnen und Umwelt GmbH</li>
            <li>Rheinstrasse 65</li>
            <li>D-64295 Darmstadt</li>
            <li>Germany</li>
            <li className="pt-2 text-cyan-700 font-medium">
              <a href="https://www.iwu.de/" target="_blank" rel="noreferrer">
                www.iwu.de &raquo;
              </a>
            </li>
          </ul>
        </div>
        <div id="fmiddle" className="flex-grow"></div>
        <div id="fright" className="flex-grow-0 w-[320px]">
          <h5 className="font-medium">Disclaimer</h5>
          <p className="text-sm pt-3">
            The sole responsibility for the content of this webpage lies with
            the authors. It does not necessarily reflect the opinion of the
            European Union. Neither the EASME nor the European Commission are
            responsible for any use that may be made of the information
            contained therein.{" "}
          </p>
        </div>
        <div id="feu" className="flex-grow-0 w-[320px] items-center">
          <img
            src="/images/eulogo.jpeg"
            alt="RentalCal Tool"
            layout="fixed"
            width="160"
            height="100"
          />
          <p className="text-xs">The project is funded by the European Union</p>
        </div>
      </div>
    </section>
  )
}
